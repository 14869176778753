<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>
                {{
                  $route.name == route.form
                    ? "Create Invoice Adjustment"
                    : "Update Invoice Adjustment"
                }}
              </strong>
              <div v-if="$route.name != route.form" class="card-header-actions">
                <b-button size="sm"
                          variant="danger"
                          v-b-tooltip.hover
                          title="Delete Adjustment"
                          @click="handleDelete">
                  Delete Adjustment
                </b-button>
              </div>
            </div>
            <b-row>
              <!--building selection-->
              <BuildingDropdown :server-params="serverParams"
                                :form-group="true"
                                :on-select="getAccounts" />
              <!--adjustment title-->
              <b-col sm="12">
                <form-group :validator="$v.form.adjustmentTitle"
                            label="Adjustment Title"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter adjustment title"
                                autocomplete="off"
                                v-model="form.adjustmentTitle"></b-form-input>
                </form-group>
              </b-col>
              <!--period-->
              <b-col sm="12">
                <form-group :validator="$v.form.period"
                            label="Period"
                            :use-horizontal="false">
                  <b-form-input type="month"
                                placeholder="Enter period"
                                autocomplete="off"
                                v-model="form.period"></b-form-input>
                </form-group>
              </b-col>
              <!--applicable to accounts-->
              <b-col sm="12" v-if="$route.name != route.form">
                <form-group label="Account"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter adjustment title"
                                autocomplete="off"
                                disabled
                                v-model="form.accountId"></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12" v-if="$route.name == route.form">
                <form-group :validator="$v.form.accounts"
                            label="Choose Accounts"
                            :use-horizontal="false">
                  <treeselect v-model="form.accounts"
                              placeholder="Select Accounts"
                              :multiple="true"
                              :options="options.account" />
                </form-group>
              </b-col>
              <!--adjustment amount-->
              <b-col sm="12">
                <form-group :validator="$v.form.adjustmentAmount"
                            label="Adjustment Amount (SGD)"
                            :use-horizontal="false">
                  <vue-numeric-input v-model="form.adjustmentAmount"
                                     :step="2"
                                     :controls="false"
                                     className="form-control"
                                     width="100%"></vue-numeric-input>
                </form-group>
                <!--add/deduct note-->
                <b-alert show variant="warning">
                  <p class="font-sm">Note: Amount can be either positive or negative</p>
                  <ul>
                    <li>if positive, the value will be added to the total.</li>
                    <li>if negative, the value will be deducted from the total.</li>
                  </ul>
                </b-alert>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm"> Back </b-button>
              <b-button type="submit"
                        size="sm"
                        variant="success"
                        class="float-right">
                Save
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { required } from "vuelidate/lib/validators";
  import BuildingDropdown from "@/components/common/BuildingDropdown";

  export default {
    data: () => ({
      route: {
        form: "InvoiceAdjustment",
      },
      serverParams: {
        buildingId: null,
      },
      form: {
        adjustmentTitle: null,
        period: null,
        adjustmentAmount: null,
        accounts: [],
        accountId: null,
        adjustmentId: null,
      },
      options: {
        account: [],
      },
    }),
    validations: {
      form: {
        period: { required },
        adjustmentTitle: { required },
        adjustmentAmount: { required },
        accounts: {},
      },
    },
    components: {
      BuildingDropdown,
    },
    created() {
      const self = this;
      this.getAccounts();
      if (self.$route.name != self.route.form) {
        this.getAdjustment();
      }
    },
    methods: {
      handleDelete() {
        const self = this;
        self.$dialog
          .confirm("You are about to delete this adjustment. Are you sure ?", {
            okText: "Yes, Delete",
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch("apis/remove", {
                url: `/transaction/invoice/adjustment/${self.$route.params.id}`,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
      getAccounts() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: "/account",
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.options.account = [
                {
                  id: "all",
                  label: "Select All",
                  children: response.data.data.map((x) => ({
                    id: x.accountId,
                    label: `${x.accountId}# ${x.name}`,
                  })),
                },
              ];
            }
          });
      },
      getAdjustment() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: "/transaction/invoice/adjustment/" + self.$route.params.id,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              if (response.data.isApplied) {
                self.$router.go(-1);
                return;
              }
              self.form = {
                ...self.form,
                adjustmentTitle: response.data.title,
                period: null,
                adjustmentAmount: response.data.amount,
                accountId: "#" + response.data.accountId,
                adjustmentId: response.data.invoiceAdjustmentID
              }
            }
          });
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText =
          "You are about to adjust the amount for invoices. Are you sure ?",
          _okText = "Yes, Continue",
          _action = "apis/post",
          _url = "/transaction/invoice/adjustment";

        if (self.$route.name != self.route.form) {
          _confirmText =
            "You are about to update the adjustment. Are you sure ?";
          _okText = "Yes, Update";
          _url = "/transaction/invoice/adjustment/update";
        }

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.form,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
    },
  };
</script>
